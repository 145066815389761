<template>
  <div @scroll="isBottom" id="list-purchase" :class="!isMobileView ? 'biz-container' :''">
    <h2 class="mb-5 title-page">
      {{ $t("menu.purchase.waiting_payment.title") }}
    </h2>
    <div class="nav-content-waiting">
      <HomeIcon />
      <ArrowIcon />
      <span>Transaksi</span>
      <ArrowIcon />
      <span style="color: black">Menunggu Pembayaran</span>
    </div>
    <div class="d-flex pr-0 justify-content-center align-items-center flex-column" v-if="!loading && data.length === 0">
      <div class="w-35 d-flex align-items-center flex-column">
        <div class="d-flex align-items-center">
          <EmptyWalletIcon />
        </div>
        <span class="ml-2 h4 mt-3">
          {{ $t('menu.purchase.waiting_payment.dont_have_transaction') }}
        </span>
        <div class="card-footer border-top-0">
          <button @click="goToHome" class="btn rounded w-100">
            {{ $t('menu.purchase.cash_delivery.shopping_now') }}
          </button>
        </div>
      </div>
    </div>
    <div v-else id="wrap" class="sub-content" v-for="({ image, bank_name, expired_at, method, total, order_id, account_number, order_number, payment_name }, key) in data"
      :key="key" @click="goToDetail(order_id)">
        <div class="top">
          <div class="the-order">
            <span class="no-order">{{ $t('menu.purchase.cash_delivery.order_number') }}</span>
            <span class="code-order">{{ order_number }}</span>
          </div>
          <div class="expired-payment">
            <span class="text-expired">{{ $t('menu.purchase.cash_delivery.pay_before') }}</span>
            <span class="date-expired">{{ expired_at }}</span>
          </div>
        </div>
        <div class="middle"></div>
        <div class="bottom">
          <div class="bank">
            <span class="logo-bank"
              >
              <img v-if="image" :src="image" :alt="image" width="120px" height="58" />
                </span>
            <div class="info-bank">
              <span v-if="method.includes('transfer')" class="bank-name">Bank Transfer</span>
              <span v-else-if="method.includes('va')" class="bank-name">{{payment_name}}</span>
              <span v-else-if="method.includes('go_pay')" class="bank-name">{{payment_name}}</span>
              <span v-else-if="method.includes('dana')" class="bank-name">{{payment_name}}</span>
              <span v-else class="bank-name">COD</span>
              <span v-if="!method.includes('go_pay')" class="bank-phone">{{ account_number }}</span>
            </div>
          </div>
          <div class="total-payment">
            <span class="title-payment">{{ $t('menu.purchase.cash_delivery.total_payment') }}</span>
            <span class="nominal-payment">Rp.{{ total }}</span>
          </div>
          <div class="action">
            <button @click="goToHowToPay(order_id)" type="button" class="btn-tutorial">{{ $t('menu.purchase.cash_delivery.how_to_pay') }}</button>
            <button @click.stop="$router.push({ path: `/purchase/order/${order_id}` })" type="button" class="btn-detail">{{ $t('menu.purchase.waiting_payment.detail_transaction') }}</button>
          </div>
          <div class="price-info-mobile">
          <div>
            <p style="margin: 0; color: gray">
              {{ $t("menu.purchase.cash_delivery.total_payment") }}
            </p>
            <span style="font-size: 16px; font-weight: bold"
              >Rp.{{ total }}</span
            >
          </div>
          <button
            @click="goToHowToPay(order_id)"
            type="button"
            class="btn-tutorial"
          >
            {{ $t("menu.purchase.cash_delivery.how_to_pay") }}
          </button>
        </div>
        </div>
      </div>
    <div class="card border-0 shadow-none col-12 d-flex align-items-end">
      <button @click="$router.go(-1)" class="btn w-25">{{ $t('menu.purchase.detail.back') }}</button>
    </div>
  </div>
</template>

<script>
import EmptyWalletIcon from '@/components/Icons/EmptyWallet.vue'
import moment from 'moment'
import ArrowIcon from '@/components/Icons/ArrowCevron.vue'
import HomeIcon from '@/components/Icons/Home.vue'

export default {
  name: 'waiting-payment',
  components: {
    EmptyWalletIcon,
    ArrowIcon,
    HomeIcon,
  },
  data: function () {
    return {
      data: [],
      loading: false,
      page: 1,
      limit: 10,
      total: 0,
    }
  },
  computed: {
    isMobileView() {
      return this.$store.state.settings.isMobileView
    },
  },
  methods: {
    getData() {
      this.loading = true
      this.$store.dispatch('getPurchaseWaitingPayment', {
        page: this.page,
        limit: this.limit,
      }).then(res => {
        this.data = res.data.data
        this.total = res.data.total
        this.loading = false
      })
    },
    goToHome() {
      this.$router.push('/dashboard')
    },
    getWaitingPayment() {
      this.loading = true
      this.$store.dispatch('purchase/GETWAITINGPAYMENT', {
        channel_id: this.$store.state.user.user_data.channel_id,
        buyer_id: this.$store.state.user.user_data.buyer_id,
        page: this.page,
      })
        .then(({ data }) => {
          this.total_waiting_payment = data.list.length
          const newData = data.list.map(({ expired_at, total, payments, ...rest }) => ({
            expired_at: moment(expired_at).format('Do MMMM YYYY, h:mm:ss'),
            total: Intl.NumberFormat('en-US').format(total),
            image: payments[0].image,
            method: payments[0].method,
            payment_name: payments[0].payment_name,
            account_number: payments[0].account_number,
            expired_date: expired_at,
            ...rest,
          }))
          // newData = newData.filter((data) => moment().isBefore(moment(data.expired_date)))
          this.data = [...this.data, ...newData]
          this.total = data.total_data
          this.loading = false
          this.page = this.page + 1
        })
        .catch(() => {
          this.loading = false
        })
    },
    goToHowToPay(id) {
      this.$router.push({ path: `/purchase/how-to-pay/${id}` })
    },
    goToDetail(id) {
      if (window.innerWidth > 800) return
      this.$router.push({ path: `/purchase/order/${id}` })
    },
    isBottom() {
      const el = document.querySelectorAll('#wrap')
      const navbarHeight = 151
      const offsetElement = 5
      const lastEl = el[el.length - offsetElement]
      const bounds = lastEl.getBoundingClientRect().y
      const alreadyGetAllData = this.data.length >= this.total
      if (!this.loading && !alreadyGetAllData && bounds < navbarHeight) {
        this.getWaitingPayment()
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.isBottom)
    this.getWaitingPayment()
  },
  created() {
    window.addEventListener('scroll', this.isBottom)
  },
  destroyed() {
    window.removeEventListener('scroll', this.isBottom)
  },
}
</script>
<style scoped>
.title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
}
.sub-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 0px;
  gap: 16px;
  margin: 20px 0;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 12px;
}
.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
  width: 100%;
}
.the-order {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;
}
.no-order {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #767676;
}
.code-order {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.expired-payment {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  gap: 2px;
}
.text-expired {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #767676;
}
.date-expired {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: var(--biz-accent-failed-toast);
}
.middle {
  border: 1px solid #f3f3f3;
  width: 100%;
  height: 0px;
}
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
  width: 100%;
}
.bank {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 32px;
}
.logo-bank {
    width: 114px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.info-bank {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;
}
.bank-name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
.bank-phone {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #929292;
}
.total-payment {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}
.title-payment {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.nominal-payment {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: right;
  color: var(--biz-brand-legacy);
}
.action {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  gap: 16px;
}
.btn-tutorial,
.btn-detail {
  width: 128px;
  height: 34px;
  border: none;
}
.btn-tutorial {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 6px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.btn-detail {
  background: var(--biz-brand-button);
  border-radius: 6px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}
.price-info-mobile {
  display: none;
}
.title-page {
  display: block;
}
.nav-content-waiting {
  display: none;
  gap: 15px;
  padding: 20px;
  color: #999999;
  align-items: center;
  overflow-x: auto;
}
@media only screen and (max-width: 800px) {
  .total-payment {
    display: none;
  }
  .action {
    display: none;
  }
  .info-bank span {
    font-size: 18px;
    font-weight: bold;
    margin-top: -25px;
  }
  .bottom {
    display: block;
  }
  .price-info-mobile {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .title-page {
    display: none;
  }
  .nav-content-waiting {
    display: flex;
    align-items: center;
  }
}
</style>
