<template>
  <svg
    :width="width"
    :height="height"
    :style="`transform: ${rotate}`"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="23" fill="#F5F5F5" />
    <g clip-path="url(#clip0_1_232)">
      <rect
        width="1280"
        height="1544"
        transform="translate(-1160 -338)"
        fill="white"
      />
      <rect
        x="-1071.5"
        y="-11.5"
        width="1103"
        height="49"
        rx="7.5"
        fill="white"
      />
      <path
        d="M14 11.5C14 11.258 13.904 11.0161 13.7123 10.8317L7.67776 5.02694C7.29388 4.65769 6.6715 4.65769 6.28779 5.02694C5.90407 5.39605 5.90407 5.99461 6.28779 6.3639L11.6275 11.5L6.28797 16.6361C5.90426 17.0053 5.90426 17.6038 6.28797 17.9729C6.67169 18.3424 7.29407 18.3424 7.67794 17.9729L13.7125 12.1683C13.9042 11.9838 14 11.7418 14 11.5Z"
        fill="black"
      />
      <rect
        x="-1071.5"
        y="-11.5"
        width="1103"
        height="49"
        rx="7.5"
        stroke="#F3F3F3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_232">
        <rect
          width="1280"
          height="1544"
          fill="white"
          transform="translate(-1160 -338)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ArrowCaveron',
  props: {
    direction: {
      type: String,
      default: 'right',
    },
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 23,
    },
  },
  computed: {
    rotate() {
      if (this.direction === 'right') {
        return 'rotate(0deg)'
      } else if (this.direction === 'left') {
        return 'rotate(180deg)'
      } else if (this.direction === 'up') {
        return 'rotate(90deg)'
      } else {
        return 'rotate(270deg)'
      }
    },
  },
}
</script>
