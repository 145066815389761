<template>
    <svg width="25" height="25" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.33333 7H1L7 1L13 7H11.6667M2.33333 7V11.6667C2.33333 12.0203 2.47381 12.3594 2.72386 12.6095C2.97391 12.8595 3.31304 13 3.66667 13H10.3333C10.687 13 11.0261 12.8595 11.2761 12.6095C11.5262 12.3594 11.6667 12.0203 11.6667 11.6667V7" stroke="#ABABAB" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 13V9.00001C5 8.64638 5.14048 8.30724 5.39052 8.0572C5.64057 7.80715 5.97971 7.66667 6.33333 7.66667H7.66667C8.02029 7.66667 8.35943 7.80715 8.60948 8.0572C8.85952 8.30724 9 8.64638 9 9.00001V13" stroke="#ABABAB" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
</template>

<script>
export default {
  name: 'HomeIcon',
}
</script>
