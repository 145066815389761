<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="299" height="299" viewBox="0 0 299 299" fill="none">
    <circle cx="149.5" cy="149.5" r="149.5" fill="#F4F4F4" />
    <path
      d="M172.627 170.293C171.295 170.293 170.018 170.814 169.076 171.743C168.134 172.671 167.605 173.93 167.605 175.243C167.605 176.556 168.134 177.815 169.076 178.744C170.018 179.672 171.295 180.194 172.627 180.194H192.711C194.042 180.194 195.32 179.672 196.261 178.744C197.203 177.815 197.732 176.556 197.732 175.243C197.732 173.93 197.203 172.671 196.261 171.743C195.32 170.814 194.042 170.293 192.711 170.293H172.627ZM77.2266 120.79C77.2266 115.538 79.3426 110.501 83.1091 106.788C86.8756 103.074 91.9841 100.988 97.3108 100.988H197.732C203.058 100.988 208.167 103.074 211.933 106.788C215.7 110.501 217.816 115.538 217.816 120.79V180.194C217.816 185.445 215.7 190.482 211.933 194.195C208.167 197.909 203.058 199.995 197.732 199.995H97.3108C91.9841 199.995 86.8756 197.909 83.1091 194.195C79.3426 190.482 77.2266 185.445 77.2266 180.194V120.79ZM207.774 120.79C207.774 118.164 206.716 115.646 204.833 113.789C202.949 111.932 200.395 110.889 197.732 110.889H97.3108C94.6474 110.889 92.0932 111.932 90.2099 113.789C88.3267 115.646 87.2687 118.164 87.2687 120.79V130.69H207.774V120.79ZM87.2687 180.194C87.2687 182.819 88.3267 185.338 90.2099 187.194C92.0932 189.051 94.6474 190.094 97.3108 190.094H197.732C200.395 190.094 202.949 189.051 204.833 187.194C206.716 185.338 207.774 182.819 207.774 180.194V140.591H87.2687V180.194Z"
      fill="#000" />
    <path
      d="M221.99 165.674C227.108 162.644 233.704 165.038 235.665 170.64C236.811 173.875 239.613 176.243 243.025 176.803C248.882 177.771 252.397 183.858 250.309 189.409C249.086 192.618 249.723 196.26 251.964 198.858C255.835 203.365 254.612 210.267 249.443 213.195C246.438 214.876 244.604 218.059 244.655 221.497C244.731 227.431 239.332 231.939 233.501 230.844C230.113 230.207 226.675 231.455 224.485 234.129C220.716 238.713 213.687 238.713 209.944 234.129C207.754 231.48 204.316 230.207 200.929 230.844C195.097 231.939 189.723 227.431 189.774 221.497C189.825 218.059 187.966 214.876 184.986 213.195C179.817 210.292 178.594 203.365 182.465 198.858C184.706 196.26 185.343 192.618 184.12 189.409C182.032 183.858 185.547 177.771 191.404 176.803C194.791 176.243 197.618 173.875 198.764 170.64C200.725 165.038 207.321 162.644 212.44 165.674C215.368 167.432 219.035 167.432 221.99 165.674Z"
      fill="#ED1C24" />
    <path
      d="M227.954 196.701V201.849C227.954 204.617 227.707 206.951 227.212 208.853C226.718 210.754 226.007 212.285 225.079 213.444C224.151 214.604 223.031 215.446 221.716 215.972C220.418 216.482 218.949 216.737 217.31 216.737C216.012 216.737 214.814 216.575 213.716 216.25C212.618 215.926 211.629 215.408 210.748 214.697C209.882 213.97 209.14 213.027 208.521 211.867C207.903 210.708 207.432 209.301 207.107 207.647C206.782 205.993 206.62 204.06 206.62 201.849V196.701C206.62 193.934 206.867 191.615 207.362 189.744C207.872 187.874 208.591 186.374 209.519 185.246C210.446 184.102 211.559 183.282 212.858 182.788C214.172 182.293 215.641 182.045 217.264 182.045C218.578 182.045 219.784 182.208 220.882 182.532C221.995 182.842 222.984 183.344 223.85 184.04C224.716 184.72 225.45 185.632 226.053 186.776C226.671 187.905 227.143 189.288 227.467 190.927C227.792 192.566 227.954 194.491 227.954 196.701ZM223.641 202.545V195.982C223.641 194.467 223.548 193.138 223.363 191.994C223.193 190.834 222.938 189.845 222.598 189.026C222.258 188.206 221.825 187.541 221.299 187.031C220.789 186.521 220.194 186.15 219.513 185.918C218.849 185.671 218.099 185.547 217.264 185.547C216.244 185.547 215.339 185.74 214.551 186.127C213.762 186.498 213.098 187.093 212.557 187.912C212.031 188.732 211.629 189.806 211.351 191.136C211.072 192.465 210.933 194.081 210.933 195.982V202.545C210.933 204.06 211.018 205.397 211.188 206.557C211.374 207.716 211.644 208.721 212 209.572C212.356 210.406 212.788 211.094 213.299 211.635C213.809 212.177 214.396 212.579 215.061 212.841C215.741 213.089 216.491 213.212 217.31 213.212C218.362 213.212 219.282 213.011 220.07 212.609C220.858 212.208 221.515 211.581 222.041 210.731C222.582 209.865 222.984 208.76 223.247 207.415C223.51 206.055 223.641 204.431 223.641 202.545Z"
      fill="white" />
    <defs>
      <linearGradient id="paint0_linear_7835_125286" x1="180.168" y1="200.977" x2="254.239" y2="200.977"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#F26522" />
        <stop offset="0.2497" stop-color="#F47D23" />
        <stop offset="1" stop-color="#F47920" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'empty-wallet',
}
</script>
